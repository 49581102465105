import React from "react"
import "./calculator.css"

export default class MortgageRepaymentCalculatorCalculator extends React.Component {
  state = {
    value: '',
    interestRate: 3.5,
    mortgageYears: 25,
    mortgageType: 'repayment'
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  parseNumber = number => {
    return Number(number).toFixed(2);
  }

  displayMonthly = () => {
    if (this.state.mortgageType === 'repayment') {
      return this.displayRepaymentMonthly();
    } else {
      return this.displayInterestOnlyMonthly();
    }
  }

  displayRepaymentMonthly = () => {
    const monthlyRate = this.state.interestRate / 100 / 12;
    const numPayments = 12 * this.state.mortgageYears;

    const partZero = 1 + monthlyRate;
    const partZeroBoth = Math.pow(partZero, numPayments);
    const partOne = partZeroBoth * monthlyRate;
    const partTwo = partZeroBoth - 1;
    return this.state.value * (partOne / partTwo);
  }

  displayInterestOnlyMonthly = () => {
    const monthlyRate = this.state.interestRate / 100 / 12;
    return this.state.value * (monthlyRate);
  }

  displayTotal = () => {
    if (this.state.mortgageType === 'repayment') {
      return this.displayRepaymentTotal();
    } else {
      return this.displayInterestOnlyTotal();
    }
  }

  displayRepaymentTotal = () => {
    const numPayments = 12 * this.state.mortgageYears;
    return Math.round(this.displayMonthly() * numPayments);
  }

  displayInterestOnlyTotal = () => {
    const numPayments = 12 * this.state.mortgageYears;
    return Math.round(this.displayMonthly() * numPayments + Number(this.state.value));
  }

  round = val => {
    if (isNaN(val)) {
      return '0';
    }
    return Math.round(val);
  }

  render() {
    return (
      <div className='calculator'>
        <form className='container'>

        <div className='form-row align-items-center'>
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="value">Mortgage value</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">£</div>
              </div>
              <input
                autoFocus
                id="value"
                type="text"
                name="value"
                value={this.state.value}
                onChange={this.handleInputChange}
                placeholder="0"
                className='number form-control'

              />
            </div>
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="interestRate">Interest rate</label>
            <div className="input-group">
              <input
                id="interestRate"
                type="text"
                name="interestRate"
                value={this.state.interestRate}
                onChange={this.handleInputChange}
                className='number form-control'
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="mortgageType">Mortgage type</label>
            <div className="input-group">
              <select
                id="mortgageType"
                type="number"
                min="1"
                max="40"
                name="mortgageType"
                value={this.state.mortgageType}
                onChange={this.handleInputChange}
                className='number form-control'
              >
                <option value="repayment">Repayment</option>
                <option value="interest-only">Interest only</option>
              </select>
            </div>
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="mortgageYears">Mortgage term</label>
            <div className="input-group">
              <input
                id="mortgageYears"
                type="number"
                min="1"
                max="40"
                name="mortgageYears"
                value={this.state.mortgageYears}
                onChange={this.handleInputChange}
                className='number form-control'
              />
              <div className="input-group-append">
                <div className="input-group-text">years</div>
              </div>
            </div>
          </div>


        </div>

        </form>

        <div style={{ textAlign: "center" }}>
          <h3 style={{ display: 'inline-block', borderBottom: '1px solid #ccc', marginBottom: '20px', paddingBottom: '5px' }}>Your calculation</h3>
          <p>Per month, you'll repay <span style={{ color: 'red' }}>£{this.round(this.displayMonthly())}</span></p>
          <p>In total, you'll repay <span style={{ color: 'red' }}>£{this.round(this.displayTotal())}</span></p>
        </div>

        {/* <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Debt remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span className='number'>£{this.displayAddNet()}</span></td>
              <td><span className='number'>£{this.displayRemoveNet()}</span></td>
            </tr>
            <tr>
              <td><span className='number'>£{this.displayAddVat()}</span></td>
              <td><span className='number'>£{this.displayRemoveVat()}</span></td>
            </tr>
            <tr>
              <td><span className='number'>£{this.displayAddGross()}</span></td>
              <td><span className='number'>£{this.displayRemoveGross()}</span></td>
            </tr>
          </tbody>
        </table> */}
        {/* <h2>Add VAT</h2>
        <p>Net: <span className='number'>{this.displayAddNet()}</span></p>
        <p>VAT: <span className='number'>{this.displayAddVat()}</span></p>
        <p>Gross: <span className='number'>{this.displayAddGross()}</span></p>
        <h2>Remove VAT</h2>
        <p>Net: <span className='number'>{this.displayRemoveNet()}</span></p>
        <p>VAT: <span className='number'>{this.displayRemoveVat()}</span></p>
        <p>Gross: <span className='number'>{this.displayRemoveGross()}</span></p> */}
      </div>
    )
  }
}